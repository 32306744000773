import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSafeAsync } from "../components/useSafeAsync";
import { db, storage } from "../components/firebase-public";
import LoadingGuard from "../components/loadingGuard";
import { Box, CircularProgress, Divider, Grid, IconButton, Link, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { Timestamp, doc, getDoc, setDoc } from "firebase/firestore";
import { CalendarMonth, Cancel, Check, Checkroom, Done, FileCopy, FolderOpen } from "@mui/icons-material";
import { UserContext } from "../components/context/userContext";
import { ref } from "firebase/storage";
import { GetImageURL } from "../components/helpers";
import Image from "mui-image";

type MissionObject = {
  id: string,
  nazev: string,
  datum: Date,
  datum_ingame?: Date,
  povinne: string,
  povolene: string,
  zakazane: string,
  dodatecne: string,
  gear: string|Object,
  deployment?: string,
  dokoncena?: string,
  typ?:string,
  valecne_pravo?: ["ano","ne"],
  sloty: Object,
  mapa: string,
  disk?: string
  briefing: string,
}

const CheckIsInSlots:any = (obj:any, user:string) =>
  Object.entries(obj)
  .some(([k, v]) => {
    if (v instanceof Object) { 
      return CheckIsInSlots(v, user);
    }
    
  return (k == "clen") && (v === user);
})

function CheckAllowedRoles (obj:any,kurzy:any, official=true){
  var role:any[] = [];
  var allowedRole = ["Zeus","Nahradnik","RFL","RGL","RAT","AR","ARFR","CLS","AR-ASST","MMG-ASST","SNP-ASST","AT-ASST","AA-ASST","ASST","LMG","MOD"]


  Object.keys(obj).map((plat:any)=>{
    Object.keys(obj[plat]).map((o:any)=>{
      if (obj[plat][o].kit.includes("/")) {
        const arr = obj[plat][o].kit.split("/");
        role.push(arr[0])
        role.push(arr[1])
      } else {
        role.push(obj[plat][o].kit)
      }
    })
  })
  role = [...new Set(role)];

  kurzy = Object.keys(kurzy).map((key)=>{
    if (kurzy[key] !== 0) return key; 
  }).filter((el)=>{return el !== undefined});

  if (!official || kurzy.includes("bct1") || kurzy.includes("bct2") || kurzy.includes("bct3")) {
    allowedRole = [...allowedRole, "RTO", "SPT", "MED"];
  }
  if (!official || kurzy.includes("ldr")) {allowedRole = [...allowedRole,"FTL"]};
  if (!official || kurzy.includes("sql")) {allowedRole = [...allowedRole,"SQL"]};
  if (!official || kurzy.includes("pco")) {allowedRole = [...allowedRole,"PCO"]};

  if (!official || kurzy.includes("helo-l") || kurzy.includes("helo-m") || kurzy.includes("helo-h") || kurzy.includes("helo-a")) {allowedRole = [...allowedRole,"PLT-RW"]};
  if (!official || kurzy.includes("cas")) {allowedRole = [...allowedRole,"PLT-FW"]};
  
  if (!official || kurzy.includes("eod")) {allowedRole = [...allowedRole,"EOD"]};
  //if (kurzy.includes("med")) {allowedRole = [...allowedRole,"MED"]};
  if (!official || kurzy.includes("mmg")) {allowedRole = [...allowedRole,"MMG"]};
  if (!official || kurzy.includes("at")) {allowedRole = [...allowedRole,"AT"]};
  if (!official || kurzy.includes("jtac")) {allowedRole = [...allowedRole,"JTAC"]};
  if (!official || kurzy.includes("uav")) {allowedRole = [...allowedRole,"UAV"]};
  if (!official || kurzy.includes("rbr")) {allowedRole = [...allowedRole,"RBR"]};
  if (!official || kurzy.includes("mxm")) {allowedRole = [...allowedRole,"SNP"]};
  if (!official || kurzy.includes("zeus")) {allowedRole = [...allowedRole,"Zeus"]};
  if (!official || kurzy.includes("ifr")) {allowedRole = [...allowedRole,"IFR"]};

  return allowedRole;

}

function CustomSortPlats(a:string, b:string){
  const sortOrder = ["MISFIT","SKYFALL","STALKER 1-1","STALKER 1-2","STALKER 1-3","HORNET 1-1","MYSTIC","BROADWAY","NAHRADNICI"]
  if (!sortOrder.includes(a)) return 1 ;
  if (!sortOrder.includes(b)) return -1;
  return sortOrder.indexOf(a) - sortOrder.indexOf(b);
};

function CustomSortSlots(a:string, b:string){
  const sortOrder = ["PHQ-1","PHQ-2","PHQ-3","PHQ-4",
                      "A-A-0","A-A-1","A-A-2","A-1-1","A-1-2","A-1-3","A-1-4","A-2-1","A-2-2","A-2-3","A-2-4", "A-3-1","A-3-2","A-3-3","A-3-4", "A-4-1","A-4-2","A-4-3","A-4-4",
                      "B-A-0","B-A-1","B-A-2","B-1-1","B-1-2","B-1-3","B-1-4","B-2-1","B-2-2","B-2-3","B-2-4","B-3-1","B-3-2","B-3-3","B-3-4",
                      "C-A-0","C-A-1","C-1-1","C-1-2","C-1-3","C-1-4","C-2-1","C-2-2","C-2-3","C-2-4",
                      "W-A-0","W-A-1","W-1-1","W-1-2","W-2-1","W-2-2",
                      "S-A-1","S-A-2",
                      "E-A-1","E-A-2",
                      "H-A-1","H-A-2",
                      "ZEUS-1","ZEUS-2","ZEUS-3","ZEUS-4","ZEUS-5",
                      "N-1","N-2","N-3","N-4","N-5","N-6","N-7","N-8","N-9","N-10","N-11","N-12","N-13","N-14","N-15","N-15","N-16","N-17","N-18","N-19","N-20"
                    ]
  if (!sortOrder.includes(a) && !sortOrder.includes(b)) return a.localeCompare(b) ;
  if (!sortOrder.includes(a)) return 1 ;
  if (!sortOrder.includes(b)) return -1;
  return sortOrder.indexOf(a) - sortOrder.indexOf(b);
};

async function SaveParticipation(missionId:string,uid:any,platoon:string,slot:string,deploymentId?:string) {
  const missionColl = (deploymentId)?doc(db,'deploymenty',deploymentId,'mise',missionId):doc(db,'mise',missionId);
  const missionSnap = await getDoc(missionColl);



  if (missionSnap.exists()) {
    var obj = missionSnap.data();
    const sloty = obj.sloty;


    const userColl = doc(db,'users',uid);
    const userSnap = await getDoc(userColl);

    if (userSnap.exists()) {
      var clenId = userSnap.data().clen;
    }

    const isAvailable = Object.keys(sloty[platoon]).map((_slot:any)=>{
      if(_slot == slot){
        sloty[platoon][_slot].clen = clenId;
        return true;
      }
      return false;
    }).includes(true);

    if (isAvailable) {
      if (deploymentId) {
        await setDoc(doc(db,'deploymenty',deploymentId,'mise',missionId), { sloty: sloty },{merge:true});
      } else {
        await setDoc(doc(db,'mise',missionId), { sloty: sloty },{merge:true});
      }
    }
  }
  
}


async function loadMission({deploymentId, missionId, uid, callbacks}:{uid:string,callbacks:any,deploymentId:string, missionId:string}) {
    const missionColl = doc(db,'deploymenty',deploymentId, 'mise', missionId);
    const missionSnap = await getDoc(missionColl);
    const deploymentColl = doc(db,'deploymenty',deploymentId);
    const deploymentSnap = await getDoc(deploymentColl);
    

    if (missionSnap.exists() && deploymentSnap.exists()) {
      var obj = missionSnap.data();
      var deployment = deploymentSnap.data();

      if (obj.dokoncena === "ne") {
        const userColl = doc(db,'users',uid);
        const userSnap = await getDoc(userColl);

        if (userSnap.exists()) {
          var clenId = userSnap.data().clen; //ziskani id prihlaseneho clena
        }

        const clenColl = doc(db,'clenove',clenId);
        const clenSnap = await getDoc(clenColl);
        if (clenSnap.exists()) {
          var kurzy = clenSnap.data().kurzy; // ziskani kurzu prihlaseneho clena
        } 
      }

      obj.id = missionSnap.id;
      obj.datum = new Timestamp(obj.datum.seconds,obj.datum.nanoseconds).toDate()

      obj.mapa = deployment.mapa;
      obj.disk = deployment.disk;

      //setState pro stav jestli je uz v prihlasovaku a ulozit povolene role
      if (callbacks && clenId && kurzy) {
        callbacks.setIsPart(CheckIsInSlots(obj.sloty,clenId));
        callbacks.setAllowedRoles(CheckAllowedRoles(obj.sloty,kurzy));
      }

      return obj;
    }
    return null;
}
async function loadSoloMission({missionId, uid, callbacks}:{uid:string,callbacks:any, missionId:string}) {
    const missionColl = doc(db,'mise', missionId);
    const missionSnap = await getDoc(missionColl);
    

    if (missionSnap.exists()) {
      var obj = missionSnap.data();

      if (obj.stav === "nezahajena" || (!obj.stav && obj.dokoncena === "ne")) {
        const userColl = doc(db,'users',uid);
        const userSnap = await getDoc(userColl);

        if (userSnap.exists()) {
          var clenId = userSnap.data().clen; //ziskani id prihlaseneho clena
        }

        const clenColl = doc(db,'clenove',clenId);
        const clenSnap = await getDoc(clenColl);
        if (clenSnap.exists()) {
          var kurzy = clenSnap.data().kurzy; // ziskani kurzu prihlaseneho clena
        } 
      }

      obj.id = missionSnap.id;
      obj.datum = new Timestamp(obj.datum.seconds,obj.datum.nanoseconds).toDate()

      //setState pro stav jestli je uz v prihlasovaku a ulozit povolene role
      if (callbacks && clenId && kurzy) {
        callbacks.setIsPart(CheckIsInSlots(obj.sloty,clenId));
        callbacks.setAllowedRoles(CheckAllowedRoles(obj.sloty,kurzy,(obj.typ != "n")));
      }
      
      return obj;
    }
    return null;
}

export default function MissionPage(props:any) {
  const { deploymentId, missionId } = useParams();
  const [missionCall, missionState] = useSafeAsync<any,any>(loadMission);
  const [soloMissionCall, soloMissionState] = useSafeAsync<any,any>(loadSoloMission);
  const [isPart, setIsPart] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [allowedRoles, setAllowedRoles] = useState<Array<string>>([]);
  const user = useContext(UserContext);
  const isDesktop = useMediaQuery('(min-width:600px)');
    

  
  useEffect(()=>{
    if (missionId && deploymentId) {
      if(user && user.user && user.userCalled){
        missionCall({
          missionId:missionId,
          deploymentId:deploymentId,
          uid:user.user.uid,
          callbacks:{setIsPart:setIsPart,setAllowedRoles:setAllowedRoles}});
      }
    }else if(missionId){
      //nacteni misi bez deploymentu (neofiko, trenink, atd);
      if(user && user.user && user.userCalled){
        soloMissionCall({
          missionId:missionId,
          uid:user.user.uid,
          callbacks:{setIsPart:setIsPart,setAllowedRoles:setAllowedRoles}});
      }
    }

  },[user?.userCalled])


  
  if (!deploymentId && missionId) {
    //NEOFIKO mise bez deploymentu
    return(<>
      <LoadingGuard state={soloMissionState} >
          {soloMissionState.value  &&
          <Stack direction={"column"}>
            <Typography variant="h4" textAlign={"center"} p={2}>{soloMissionState.value.nazev}</Typography>
            <Stack direction={(isDesktop)?"row":"column-reverse"} spacing={1} p={2}>
              <Box component={Paper} width={(isDesktop)?"50%":"100%"} p={1}>
                <Grid container spacing={1}>
                  {Object.keys(soloMissionState.value.sloty).sort((a:string,b:string) => (Object.keys(soloMissionState.value.sloty).find((plat:string) => plat === "BROADWAY"))?CustomSortPlats(a,b):a.localeCompare(b)).map((plat)=>{
                    return(
                    <PlatGroup 
                      key={plat}
                      value={soloMissionState.value.sloty[plat]} 
                      platoon={plat} 
                      isPart={isPart}
                      allowedRoles={allowedRoles}
                      clicked={clicked}
                      disableAllowedRoles={(soloMissionState.value.typ === "o")?false:true}
                      missionDone={(soloMissionState.value.dokoncena !== "ne" || (Date.now() - 30 * 60 * 1000) > soloMissionState.value.datum)}
                    />
                    )

                  })}
                </Grid>
              </Box>
              <Box component={Paper} width={(isDesktop)?"50%":"100%"} p={1}>
                <Grid container rowSpacing={1} alignItems={"center"}>
                  <Grid item xs={3}><Typography >Stav:</Typography></Grid>
                  <Grid item xs={9}>{(soloMissionState.value.dokoncena === "ne")?<Cancel color="error" />:<Check color="success" />}</Grid>

                  <Grid item xs={3}><Typography >Typ:</Typography></Grid>
                  <Grid item xs={9}>{
                  (soloMissionState.value.typ === "o")? <Typography variant="body2" color={"#00df00"}>Oficiální</Typography>
                  :((soloMissionState.value.typ === "n")? <Typography variant="body2" color={"#ff2323"}>Neoficiální</Typography>
                  : <Typography variant="body2" color={"#fffc23"}>Trénink</Typography>
                  )}
                  </Grid>

                  <Grid item xs={3}><Typography >Datum:</Typography></Grid>
                  <Grid item xs={9}><Typography>{soloMissionState.value.datum.toLocaleString("cs-CZ",{weekday:"long"})} {soloMissionState.value.datum.toLocaleTimeString("cs-CZ").slice(0,-3)}</Typography></Grid>

                  <Grid item xs={3}></Grid>
                  <Grid item xs={9}><Stack direction={"row"} alignItems={"center"} spacing={0.5}><CalendarMonth /><Typography >{soloMissionState.value.datum.toLocaleDateString("cs-CZ")}</Typography></Stack></Grid>

                  <Grid item xs={3}><Typography >Google disk:</Typography></Grid>
                  <Grid item xs={9}><Link href={soloMissionState.value.disk} target="__blank" title="Google Disk" ><IconButton><FolderOpen /></IconButton></Link></Grid>

                  <Grid item xs={3}><Typography >Gear:</Typography></Grid>
                  <Grid item xs={9}><Link href={soloMissionState.value.gear} target="__blank" title="Gear" ><IconButton><Checkroom /></IconButton></Link></Grid>

                  <Grid item xs={3}><Typography >Briefing:</Typography></Grid>
                  <Grid item xs={9}><Link href={soloMissionState.value.briefing} target="__blank" title="Briefing" ><IconButton><FileCopy /></IconButton></Link></Grid>

                  <Grid item xs={3}><Typography >Válečné právo:</Typography></Grid>
                  <Grid item xs={9}><Typography >{(soloMissionState.value.valecne_pravo === "ano")?<Check color="success" />:<Cancel color="error" />}</Typography></Grid>

                  <Grid item xs={3}><Typography >Mapa:</Typography></Grid>
                  <Grid item xs={9}><Typography >{soloMissionState.value.mapa}</Typography></Grid>

                  <Grid item xs={12}><ImageLoader image={soloMissionState.value.image} /></Grid>

                  <Grid item xs={12}><Divider /></Grid>

                  <Grid item xs={3}><Typography >Povinné:</Typography></Grid>
                  <Grid item xs={9} mt={1}><Typography variant="body1">{soloMissionState.value.povinne}</Typography></Grid>

                  <Grid item xs={3}><Typography >Povolené:</Typography></Grid>
                  <Grid item xs={9} mt={1}><Typography variant="body1">{soloMissionState.value.povolene}</Typography></Grid>

                  <Grid item xs={3}><Typography >Zakázané:</Typography></Grid>
                  <Grid item xs={9} mt={1}><Typography variant="body1">{soloMissionState.value.zakazane}</Typography></Grid>

                  <Grid item xs={3}><Typography >Dodatečné:</Typography></Grid>
                  <Grid item xs={9} mt={1}><Typography variant="body1">{soloMissionState.value.dodatecne}</Typography></Grid>

                </Grid>
              </Box>
            </Stack>
          </Stack>
          
          }
      </LoadingGuard>
    </>)
  }

  return(<>
    <LoadingGuard state={missionState} >
        {missionState.value &&
        <Stack direction={"column"}>
          <Typography variant="h4" textAlign={"center"} p={2}>{missionState.value.nazev}</Typography>
          <Stack direction={(isDesktop)?"row":"column-reverse"} spacing={1} p={2}>
            <Box component={Paper} width={(isDesktop)?"50%":"100%"} p={1}>
              <Grid container spacing={1}>
                {Object.keys(missionState.value.sloty).sort(CustomSortPlats).map((plat)=>{
                  return(
                  <PlatGroup 
                    key={plat}
                    value={missionState.value.sloty[plat]} 
                    platoon={plat} 
                    isPart={isPart}
                    allowedRoles={allowedRoles}
                    clicked={clicked}
                    missionDone={(missionState.value.dokoncena !== "ne" || (Date.now() - 30 * 60 * 1000) > missionState.value.datum)}
                  />
                  )
                })}
              </Grid>
            </Box>
            <Box component={Paper} width={(isDesktop)?"50%":"100%"} p={1}>
              <Grid container rowSpacing={1} alignItems={"center"}>
                <Grid item xs={3}><Typography >Stav:</Typography></Grid>
                <Grid item xs={9}>{(missionState.value.dokoncena === "ne")?<Cancel color="error" />:<Check color="success" />}</Grid>

                <Grid item xs={3}><Typography >Datum:</Typography></Grid>
                <Grid item xs={9}><Typography>{missionState.value.datum.toLocaleString("cs-CZ",{weekday:"long"})} {missionState.value.datum.toLocaleTimeString("cs-CZ").slice(0,-3)}</Typography></Grid>

                <Grid item xs={3}></Grid>
                <Grid item xs={9}><Stack direction={"row"} alignItems={"center"} spacing={0.5}><CalendarMonth /><Typography >{missionState.value.datum.toLocaleDateString("cs-CZ")}</Typography></Stack></Grid>

                <Grid item xs={3}><Typography >Google disk:</Typography></Grid>
                <Grid item xs={9}><Link href={missionState.value.disk} target="__blank" title="Google Disk" ><IconButton><FolderOpen /></IconButton></Link></Grid>

                <Grid item xs={3}><Typography >Gear:</Typography></Grid>
                <Grid item xs={9}><Link href={missionState.value.gear} target="__blank" title="Gear" ><IconButton><Checkroom /></IconButton></Link></Grid>

                <Grid item xs={3}><Typography >Briefing:</Typography></Grid>
                <Grid item xs={9}><Link href={missionState.value.briefing} target="__blank" title="Briefing" ><IconButton><FileCopy /></IconButton></Link></Grid>

                <Grid item xs={3}><Typography >Mapa:</Typography></Grid>
                <Grid item xs={9}><Typography >{missionState.value.mapa}</Typography></Grid>

                <Grid item xs={12}><ImageLoader image={missionState.value.image} /></Grid>

                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={3}><Typography >Povinné:</Typography></Grid>
                <Grid item xs={9} mt={1}><Typography variant="body1">{missionState.value.povinne}</Typography></Grid>

                <Grid item xs={3}><Typography >Povolené:</Typography></Grid>
                <Grid item xs={9} mt={1}><Typography variant="body1">{missionState.value.povolene}</Typography></Grid>

                <Grid item xs={3}><Typography >Zakázané:</Typography></Grid>
                <Grid item xs={9} mt={1}><Typography variant="body1">{missionState.value.zakazane}</Typography></Grid>

                <Grid item xs={3}><Typography >Dodatečné:</Typography></Grid>
                <Grid item xs={9} mt={1}><Typography variant="body1">{missionState.value.dodatecne}</Typography></Grid>

              </Grid>
            </Box>
          </Stack>
        </Stack>
        }
    </LoadingGuard>
  </>)
}

function ImageLoader(props:any) {
  const [img, setImg] = useState("");
  const [imgCalled, setImgCalled] = useState(false);

  useEffect(()=>{
    if (props.image) {
      GetImageURL(ref(storage,'zeus/'+props.image)).then(url => {setImg(url);setImgCalled(true)}).catch(e => {console.log(e); setImgCalled(true)})
    }else{
      console.log("Chybí obrázek v DB"+` (${props.image})`); setImgCalled(true);
    }
  },[])


  if (!imgCalled) {
    return(<Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'}><CircularProgress /></Box>);
  } else {
    return(<Image src={img} />);
  }
}

function PlatGroup(props:any) {
  const { deploymentId, missionId } = useParams();
  const [empty,setEmpty] = useState(true);
  const uc = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');

  if (props.clicked) {
    setTimeout(function(){ window.location.reload(); }, 5000);
    return(<>
      <Typography variant="body2">Uloženo, zkontroluj si slot!!</Typography>
    </>)
  }

  return(<>
    {(empty)?<></>:<Grid item xs={12} mt={2} key={`${props.platoon}-1`} ><Typography variant="h4">{props.platoon.toUpperCase()}: </Typography></Grid>}
    <Grid item container xs={12} md={6} spacing={1}  key={`${props.platoon}-2`} alignItems={"center"}>
    { Object.keys(props.value).sort(CustomSortSlots).map((slot:any)=>{
        const obj = props.value[slot];
        if (!obj.povoleno) {
          return null
        }
        if (empty) setEmpty(false)

        //zkontroluj zda ma dane kurzy pro roli
        var allowedRole = false;
        if (obj.kit.includes("/")) {
          const arr = obj.kit.split("/");
          allowedRole = (props.allowedRoles.includes(arr[0]) && props.allowedRoles.includes(arr[1]))
        } else {
          allowedRole = props.allowedRoles.includes(obj.kit);
        }
        if(props.disableAllowedRoles) allowedRole = true;


        var bckg = "";
        var slotSX;
        if (/.*FTL.*/.test(obj.kit)) {
          slotSX={borderTop:"1px dotted white"}
        }
        return(<Fragment key={`${props.platoon}-${slot}`}>
          {(/.*SQL.*/.test(obj.kit) || /.*PCO.*/.test(obj.kit))?<Grid item xs={12} sx={{borderTop:"1px solid white"}}></Grid>:<></>}
          <Grid key={`${props.platoon}-${slot}-1`} item sx={slotSX} xs={3} md={3} ><Typography variant={(isNotPhone)?"body1":"body2"}>{slot}</Typography></Grid>
          <Grid key={`${props.platoon}-${slot}-2`} item sx={slotSX} xs={4}><Typography variant={(isNotPhone)?"body1":"body2"}>{obj.kit}</Typography></Grid>
          <Grid key={`${props.platoon}-${slot}-3`} item sx={slotSX} xs={3} md={3}><Typography variant={(isNotPhone)?"body1":"body2"}>{(obj.clen)?obj.clen:"--------"}</Typography></Grid>
          {(!props.isPart && allowedRole && !props.clicked && !obj.clen && !props.missionDone)
            ?<Grid item xs={1} key={`${props.platoon}-${slot}-4`} ><IconButton onClick={()=>{SaveParticipation(missionId!,uc?.user.uid,props.platoon,slot,deploymentId).then(()=>{window.location.reload()})}}><Done /></IconButton></Grid>
            :<Grid item xs={1} key={`${props.platoon}-${slot}-4`} ></Grid>}
        </Fragment>)
      })
    }
    </Grid>
  </>)
}